import { environment } from '../../environments/handyhand/environment.dk';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';

import { HeaderComponent } from '@components/header/header.component';
import { Subject } from 'rxjs';

import { CookieService } from '@services/logic/cookie.service';
import { EventService } from '@services/logic/event.service';
import { GrowthBookService } from '@app/growthbook';
import { StorageService } from '@services/logic/storage.service';

@Component({
    selector: 'app-root',
    imports: [CommonModule, HeaderComponent, RouterOutlet],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private adClickIds = [
    'gclid',
    'wbraid',
    'gbraid',
    'msclkid',
    'fbclid',
    'SsCid',
    'TTCLID',
    'twclid',
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private growthbook: GrowthBookService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private storage: StorageService,
    private eventService: EventService,
    private cookieService: CookieService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: Event) => {
          if (event instanceof NavigationEnd) {
            this.growthbook.setURL(this.router.url);
            this.growthbook.updateAttributes({ url: this.router.url });
            this.growthbook.refreshFeatures();
          }
        });
    }
  }

  ngOnInit() {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      //this.handleGClid();
      this.listenToRoutes();
      this.initializeServerSideTracking();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private listenToRoutes() {
    // Google Tracking
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        this.eventService.customNewPageEvent(this.storage?.user, item.url);
      }
    });
  }

  private initializeServerSideTracking() {
    try {
      this.route.queryParams.subscribe((params) => {
        if (params) {
          Object.entries(params).forEach(([key, value]) => {
            this.storeAdsClick(key, value);
          });
        }
      });
      this.adClickIds.forEach((id) => {
        const value = this.cookieService.getCookie(id);
        if (value) {
          this.storeAdsClick(id, value);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  storeAdsClick(key: string, value: any): void {
    if (!key || !value || !this.adClickIds.includes(key)) {
      return;
    }

    if (this.storage.adsClickObj) {
      if (Array.isArray(this.storage.adsClickObj)) {
        if (
          !this.storage.adsClickObj.some(
            (entry) =>
              entry.adsClickType === key && entry.adsClickValue === value,
          )
        ) {
          this.storage.adsClickObj.push({
            adsClickType: key,
            adsClickValue: value,
            clickTimeStamp: new Date().toISOString(),
          });
        }
      } else {
        if (
          this.storage.adsClickObj.adsClickType !== key &&
          this.storage.adsClickObj.adsClickValue !== value
        ) {
          this.storage.adsClickObj = [
            this.storage.adsClickObj,
            {
              adsClickType: key,
              adsClickValue: value,
              clickTimeStamp: new Date().toISOString(),
            },
          ];
        }
      }
    } else {
      this.storage.adsClickObj = [
        {
          adsClickType: key,
          adsClickValue: value,
          clickTimeStamp: new Date().toISOString(),
        },
      ];
    }
  }
}
