import { environment } from '../../environments/handyhand/environment.dk';
import { Routes } from '@angular/router';

const regionsCityRoutes = {
  path: ':region',
  children: [
    {
      path: `${environment.seo.region}`,
      loadComponent: () =>
        import('../pages/seo/seo.component').then((c) => c.SeoComponent),
    },
    {
      path: `:city/${environment.seo.city}`,
      loadComponent: () =>
        import('../pages/seo/seo.component').then((c) => c.SeoComponent),
    },
  ],
};

// Routes for jobs with hh, hh/r, and hh/b suffixes
const hhRoutes = [
  {
    path: ':region/r',
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: ':region/:city/b',
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: ':subCategory/:region/r',
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: ':subCategory/:region/:city/b',
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
];

const jobsAndFindRoutes = [
  {
    path: 'kategorier',
    loadComponent: () =>
      import('../pages/seo/seo-categories/seo-categories.component').then(
        (c) => c.SeoCategoriesComponent,
      ),
  },
  {
    path: ':headCategory/:subCategory',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../pages/seo/seo.component').then((c) => c.SeoComponent),
      },
      regionsCityRoutes,
    ],
  },
  {
    path: ':headCategory',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../pages/seo/seo.component').then((c) => c.SeoComponent),
      },
      regionsCityRoutes,
      ...hhRoutes,
    ],
  },
];

export const jobRoutes: Routes = [
  {
    path: 'jobs',
    children: jobsAndFindRoutes,
  },
];
