<div class="header_container" id="header_container">
  <div class="mobile_header">
    <button
      type="button"
      aria-label="mobile menu"
      class="burger-button"
      [matMenuTriggerFor]="mobileNoAuthMenu"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
    >
      <mat-icon>{{ isMenuOpen ? 'close' : 'menu' }}</mat-icon>
    </button>

    @if (!isAuth) {
      <a routerLink="/" class="handyhand_logo_header">
        <img
          class="logo_in_header"
          ngSrc="https://d10a6kin4dgxel.cloudfront.net/private/compressed/lp/webp/hh_logo_blue.webp"
          priority
          alt="logo"
          width="130"
          height="30"
        />
      </a>
    }

    <div class="header_search_container">
      <a *ngIf="!isSearchVisible" (click)="toggleSearch($event)" class="link">
        <mat-icon
          fontIcon="search"
          style="
            color: var(--grey_7);
            font-size: 35px;
            height: 35px;
            width: 35px;
          "
        ></mat-icon>
      </a>
      @if (!isSearchVisible) {
        @if (isAuth) {
          <button
            class="back_to_platform blue_button handyhand_button"
            (click)="externalService.goToLanding()"
          >
            {{ 'NAVIGATION.BTN.BACK_TO_PLATFORM' | translate }}
          </button>
        } @else {
          <button
            style="background-color: transparent"
            aria-label="Opret opgave"
          >
            <a
              (click)="externalService.goToCreateTask()"
              class="create_task_circle_cta_mobile"
              aria-label="Opret en opgave"
            >
              <mat-icon>add</mat-icon>
            </a>
          </button>
        }
      }
      <div class="search_inner animated fadeIn" *ngIf="isSearchVisible">
        <div class="search_holder">
          <global-search-engine
            #globalSearchEngine
            class="header_search_input"
            [placeHolderText]="'Hvad søger du?'"
            [autoFocus]="true"
          ></global-search-engine>
        </div>

        <button class="close_search" (click)="toggleSearch($event)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-menu
      #mobileNoAuthMenu="matMenu"
      yPosition="below"
      xPosition="after"
      style="border-radius: 0"
      class="mobile_nav_no_auth"
    >
      <button
        class="mobile_menu_item mobile_nav_items"
        mat-menu-item
        (click)="$event.stopPropagation(); navigateTo('kategorier')"
      >
        {{ 'GENERAL.BTN.CATS' | translate }}
      </button>

      @if (!isAuth) {
        <a
          class="mobile_menu_item mobile_nav_items"
          (click)="$event.stopPropagation(); openLoginPage()"
          mat-menu-item
        >
          {{ 'NAVIGATION.URL.LOGIN' | translate | titlecase }}
        </a>
        <a
          class="mobile_menu_item mobile_nav_items"
          (click)="$event.stopPropagation(); openSignupPage()"
          mat-menu-item
        >
          {{ 'NAVIGATION.URL.SIGN_UP' | translate | titlecase }}
        </a>
      }

      <a
        class="mobile_menu_item"
        [href]="externalService.tasksUrl"
        mat-menu-item
      >
        <div class="mobile_nav_items">
          {{ 'NAVIGATION.TXT.SEE_TASKS' | translate }}
        </div>
      </a>
      <a
        class="mobile_menu_item"
        routerLink="/{{ 'NAVIGATION.URL.HOW_IT_WORKS' | translate }}"
        mat-menu-item
      >
        <div class="mobile_nav_items">
          {{ 'NAVIGATION.TXT.HOW_IT_WORKS' | translate }}
        </div>
      </a>
      <button
        class="mobile_menu_item gtm_happyhelper_refer_cleaning_mobile_menu"
        (click)="$event.stopPropagation(); goToUserHappyHelper()"
        mat-menu-item
      >
        <div style="display: block" class="mobile_nav_items">
          Fast rengøring
        </div>
      </button>
      <a
        class="mobile_menu_item"
        routerLink="/{{ 'NAVIGATION.URL.ABOUT_US' | translate }}"
        mat-menu-item
      >
        <div class="mobile_nav_items">
          {{ 'NAVIGATION.TXT.ABOUT_US' | translate }}
        </div>
      </a>
      <a
        class="mobile_menu_item"
        routerLink="/{{ 'NAVIGATION.URL.HELP' | translate }}"
        mat-menu-item
      >
        <div class="mobile_nav_items">
          {{ 'NAVIGATION.URL.HELP' | translate | titlecase }}
        </div>
      </a>
      <a
        class="mobile_menu_item"
        routerLink="/{{ 'NAVIGATION.URL.DAMAGE_COMPENSATION' | translate }}"
        mat-menu-item
      >
        <div class="mobile_nav_items">
          {{ 'NAVIGATION.URL.DAMAGE_COMPENSATION' | translate | titlecase }}
        </div>
      </a>
      <button
        class="mobile_menu_item"
        (click)="$event.stopPropagation(); switchLanguageNav()"
        mat-menu-item
      >
        <div style="display: block" class="mobile_nav_items">
          {{ 'PAGES.LANDING.LANG_SWITCH' | translate }}

          <img
            style="margin-top: 9px; margin-right: 23px"
            id="language_config_flag"
            height="20"
            width="20"
            loading="lazy"
            ngSrc="{{ reversedFlag || defaultImage }}"
            alt="skift sprog flag"
          />
        </div>
      </button>
    </mat-menu>
  </div>
  <div class="desktop_header">
    <div class="desktop_header_left">
      @if (logoRouterLink) {
        <a
          [routerLink]="'/'"
          class="handyhand_logo_header"
          (click)="switchPage()"
        >
          <img
            class="logo_in_header"
            ngSrc="https://d10a6kin4dgxel.cloudfront.net/private/compressed/lp/webp/hh_logo_blue.webp"
            alt="logo"
            width="130"
            height="30"
            priority
          />
        </a>
      }

      @if (isOddSize) {
        <a
          (click)="externalService.goToCreateTask()"
          class="create_task_circle_cta_mobile"
          aria-label="Opret en opgave"
        >
          <mat-icon>add</mat-icon>
        </a>
      } @else {
        <button
          class="call_to_action_create_task"
          (click)="externalService.goToCreateTask()"
        >
          {{ 'NAVIGATION.TXT.CREATE_TASK' | translate }}
        </button>
      }

      <app-vertical-link-box-component
        [currentTranslationLanguageTrigger]="currentTranslationLanguageTrigger"
      ></app-vertical-link-box-component>

      @if (!isOddSize) {
        <a
          class="link link_how_to"
          routerLink="/{{ 'NAVIGATION.URL.HOW_IT_WORKS' | translate }}"
        >
          {{ 'NAVIGATION.TXT.HOW_IT_WORKS' | translate }}
        </a>
      }
    </div>

    <div class="desktop_header_right">
      <div class="header_search_container">
        <div
          class="search_inner animated fadeIn"
          [ngClass]="{ widen_search_bar: isSearchVisible }"
          (click)="toggleSearch($event)"
        >
          <div class="search_holder">
            <global-search-engine
              #globalSearchEngine
              class="header_search_input"
              [placeHolderText]="
                'NAVIGATION.TXT.SEARCH_PLACEHOLDER' | translate
              "
              [autoFocus]="false"
            ></global-search-engine>
          </div>
          @if (isSearchVisible) {
            <button class="close_search" (click)="toggleSearch($event)">
              <mat-icon>close</mat-icon>
            </button>
          }
        </div>
      </div>
      <div
        class="desktop_header_right_inner"
        [ngClass]="{ shrink_non_search_bar: isSearchVisible }"
      >
        <a class="link" [href]="externalService.tasksUrl">
          {{ 'NAVIGATION.TXT.SEE_TASKS' | translate }}
        </a>
        @if (isAuth) {
          <button
            class="back_to_platform blue_button handyhand_button"
            (click)="externalService.goToLanding()"
          >
            {{ 'NAVIGATION.BTN.BACK_TO_PLATFORM' | translate }}
          </button>
        } @else {
          <p class="link" (click)="openSignupPage()">
            {{ 'NAVIGATION.URL.SIGN_UP' | translate | titlecase }}
          </p>

          <p class="link" (click)="openLoginPage()">
            {{ 'NAVIGATION.URL.LOGIN' | translate | titlecase }}
          </p>
        }

        <div class="language_select" (click)="toggleDropdown()">
          @if (lang?.flag === 'dk' && environment.cloudFrontImgUrl) {
            <img
              alt="dansk"
              height="15"
              width="15"
              ngSrc="{{ environment.cloudFrontImgUrl }}/private/denmark.png"
              priority
              class="flag-icon flag-icon-{{ lang?.flag }}"
            />
          }
          @if (lang?.flag === 'gb' && environment.cloudFrontImgUrl) {
            <img
              alt="eng"
              height="15"
              width="15"
              priority
              ngSrc="https://d10a6kin4dgxel.cloudfront.net/private/united-kingdom.png"
              class="flag-icon flag-icon-{{ lang?.flag }}"
            />
          }
          <mat-icon>arrow_drop_down</mat-icon>

          @if (isDropdownOpen) {
            <div class="custom-dropdown" #dropdown>
              @for (item of languages; track $index) {
                <div class="select_row" (click)="setLanguage(item, true)">
                  <p>{{ item.name }}</p>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
