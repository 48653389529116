import {
  Component,
  OnInit,
  HostListener,
  PLATFORM_ID,
  ViewChild,
  Inject,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterLink } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatMenuTrigger } from '@angular/material/menu';

import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '@services/http/auth.service';
import { ExternalService } from '@app/services/http/external.service';
import { StorageService } from '@services/logic/storage.service';
import { TranslateService } from '@services/logic/translate.service';

import { environment } from '../../../environments/handyhand/environment.dk';

import { VerticalLinkBoxComponent } from '@components/header/verticalLinkBox/verticalLinkBox';
import { TranslatePipe } from '@app/pipes/translate.pipe';
import { GlobalSearchEngineComponent } from '@components/seoPageElements/global-search-engine/global-search-engine.component';

@Component({
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        RouterLink,
        VerticalLinkBoxComponent,
        TranslatePipe,
        NgOptimizedImage,
        GlobalSearchEngineComponent,
    ],
    providers: [
        AuthService,
        StorageService,
        TranslateService,
        TranslatePipe,
        ExternalService,
    ],
    selector: 'app-header-not-authenticated',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild(VerticalLinkBoxComponent)
  verticalLinkBoxComponent: VerticalLinkBoxComponent =
    new VerticalLinkBoxComponent();
  @ViewChild('dropdown') dropdown: ElementRef | undefined;
  @ViewChild('globalSearchEngine', { static: false })
  globalSearchEngine?: ElementRef;
  protected readonly environment = environment;
  public isAuth: boolean = false;
  private isBrowser: boolean;
  public reversedFlag?: string;
  public defaultImage = '/assets/svg/logo.svg';
  public languages = [
    {
      name: 'Danish',
      value: 'dk',
      flag: 'dk',
      flagImg: `${environment.cloudFrontImgUrl}/private/denmark.png`,
    },
    {
      name: 'English',
      value: 'en',
      flag: 'gb',
      flagImg: `${environment.cloudFrontImgUrl}/private/compressed/united-kingdom.webp`,
    },
  ];
  public isMenuOpen: boolean = false;
  public verticalsHasBeenFetched: boolean = false;
  public logoRouterLink: string = '/';
  public logo = `${environment.cloudFrontImgUrl}/private/compressed/lp/webp/hh_logo_blue.webp`;

  isSearchVisible = false;
  currentTranslationLanguageTrigger: string | undefined;
  isMobileScreen = false;
  currentParams: any;
  innerWidth?: number;
  isDropdownOpen = false;
  isOddSize = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
    public externalService: ExternalService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.isAuth = false;
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  get lang() {
    if (this.isBrowser) {
      return this.storage.lang;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize = () => {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
      this.isMobileScreen = this.innerWidth <= 700;
      this.isOddSize = this.innerWidth > 650 && this.innerWidth < 1030;
      this.changeDetectorRef.detectChanges();
    }
  };

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.isBrowser) {
      const target = event.target as HTMLElement;
      const searchInputClass = 'global-search-input';
      if (target.classList.contains(searchInputClass)) {
        return;
      }
      this.isSearchVisible = false;
    }
  }

  ngOnInit() {
    this.setLanguageDefault();
    if (this.isBrowser) {
      this.isAuth = this.authService.isAuthenticated();
      this.innerWidth = window.innerWidth;
      this.isMobileScreen = this.innerWidth <= 700;
      this.isOddSize = this.innerWidth > 650 && this.innerWidth < 1030;
    }
    this.initLanguage(this.storage?.lang?.value);
  }

  public changeLocalLanguage(lang: any, reloadPage: boolean = true) {
    this.storage.lang = lang;
    this.translateService.use(this.lang?.value);
    this.currentTranslationLanguageTrigger = this.storage.lang?.value;
    if (reloadPage && this.isBrowser) {
      return window.location.reload();
    }
    this.changeDetectorRef.detectChanges();
  }

  private initLanguage(value: string) {
    const lang = this.languages.find((item) => item.value === value);
    if (lang && this.lang && lang.value !== this.lang.value) {
      this.setLanguage(lang, false);
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleSearch(event: MouseEvent): void {
    console.log('Toggle search event:', event); // Debugging statement
    event.stopPropagation(); // Prevent the click event from propagating to the document
    this.isSearchVisible = !this.isSearchVisible;
  }

  setLanguageDefault() {
    if (this.storage.lang) {
      if (this.storage.lang.value !== 'dk') {
        this.setLanguage(this.lang, false);
      }
    } else if (!this.lang) {
      this.storage.lang = this.languages[0];
      this.setLanguage(this.lang, false);
    }
  }

  setLanguage(lang: any, reloadPage: boolean = true) {
    this.changeLocalLanguage(lang, reloadPage);
  }

  public switchLanguageNav() {
    const currentLanguage = this.storage?.lang?.value;
    const newLanguage = this.languages.find((x) => x.value !== currentLanguage);
    this.setLanguage(newLanguage, true);
  }

  navigateTo(path: string) {
    return this.router.navigate([path]);
  }

  switchPage() {
    const url = this.router.url.split('?')[0];
    if (url !== '/') {
      this.navigateTo('/');
    }
  }

  openSignupPage() {
    this.currentParams = this.router.url;
    return this.externalService.goToSignup({
      target: '_self',
    });
  }

  openLoginPage() {
    this.currentParams = this.router.url;
    return this.externalService.goToLogin();
  }

  public menuOpened() {
    this.reversedFlag = this.languages.find(
      (x) => x.value !== this.storage?.lang?.value,
    )?.flagImg;
    this.isMenuOpen = true;
    this.verticalsHasBeenFetched = true;
  }

  public menuClosed() {
    this.isMenuOpen = false;
  }

  goToUserHappyHelper(): void {
    if (this.isBrowser) {
      window.open(
        'https://happyhelper.dk?utm_medium=website_menu&utm_source=handyhand&utm_campaign=happy_handy_colab',
      );
    }
  }
}
