import { Routes } from '@angular/router';
import { mainCatRoutes, seoRoutes } from '@app/routes/seo.routes';
import { jobRoutes } from '@app/routes/jobs.routes';
import { environment } from '../../environments/handyhand/environment.dk';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@app/pages/landing-page/landing-page.component').then(
        (c) => c.LandingPageComponent,
      ),
  },
  { path: '', pathMatch: 'full', redirectTo: 'landing' },
  {
    path: 'app',
    loadComponent: () =>
      import('@app/pages/app/app-landing/app-landing.component').then(
        (c) => c.AppLandingComponent,
      ),
  },
  {
    path: 'skribent/saxo',
    loadComponent: () =>
      import('@app/pages/contentAuthorPage/contentAuthorPage.component').then(
        (c) => c.ContentAuthorComponent,
      ),
  },
  {
    path: 'blog/:slug',
    loadComponent: () =>
      import('@app/pages/blog/details/blog.detail.component').then(
        (c) => c.BlogDetailComponent,
      ),
  },
  {
    path: 'blog',
    loadComponent: () =>
      import('@app/pages/blog/blog.component').then((c) => c.BlogComponent),
  },
  {
    path: 'beregn-indtaegt',
    loadComponent: () =>
      import('@app/pages/earningsCalculator/earningsCalculator.component').then(
        (c) => c.EarningsCalculatorComponent,
      ),
  },
  {
    path: 'handyhander-niveauer',
    loadComponent: () =>
      import(
        '@app/components/handyhandLevels/handyhanderLevels.component'
      ).then((c) => c.HandyhanderLevelsComponent),
  },
  {
    path: 'kategorier',
    loadComponent: () =>
      import('@app/pages/seo/seo-categories/seo-categories.component').then(
        (m) => m.SeoCategoriesComponent,
      ),
  },
  {
    path: 'skadegodtgoerelse',
    loadComponent: () =>
      import('@app/pages/insurance/insurance.component').then(
        (c) => c.InsuranceComponent,
      ),
  },
  {
    path: 'privatlivspolitik',
    loadComponent: () =>
      import('@app/pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent,
      ),
  },
  {
    path: 'forretningsbetingelser',
    children: [
      {
        path: 'sletning-af-konto',
        loadComponent: () =>
          import(
            '@app/pages/terms-and-conditions/delete-account/delete-account.component'
          ).then((c) => c.DeletionComponent),
      },
      {
        path: '',
        loadComponent: () =>
          import(
            '@app/pages/terms-and-conditions/terms-and-conditions.component'
          ).then((c) => c.TermsAndConditionsComponent),
      },
    ],
  },
  {
    path: 'hvemervi',
    loadComponent: () =>
      import('@app/pages/aboutUs/aboutUs.component').then(
        (c) => c.AboutUsComponent,
      ),
  },
  {
    path: 'findarbejde',
    loadComponent: () =>
      import('@app/pages/handyhanderLanding/handyhanderLanding').then(
        (c) => c.HandyhanderLanding,
      ),
  },
  {
    path: 'index/inspiration-til-opgaver-i-hjemmet',
    loadComponent: () =>
      import('@app/pages/guides/inspirations/inspirationList.component').then(
        (c) => c.InspirationListComponent,
      ),
  },
  {
    path: 'index/pris-guides-estimat-overslag',
    loadComponent: () =>
      import(
        '@app/pages/guides/priceGuides/priceGuidesOverview/priceGuidesOverview'
      ).then((c) => c.PriceGuidesOverviewComponent),
  },
  {
    path: environment.seo.aiGuidePrefix,
    loadComponent: () =>
      import(
        '@app/pages/guides/aiPriceGuidesOverview/aiPriceGuidesOverview'
      ).then((c) => c.AiPriceGuidesOverviewComponent),
  },
  {
    path: 'tips/index',
    loadComponent: () =>
      import('@app/pages/tips-index/tips-index.component').then(
        (c) => c.TipsIndexComponent,
      ),
  },
  {
    path: 'saadan-virker-handyhand',
    loadComponent: () =>
      import('@app/pages/howItWorksPage/howItWorksPage.component').then(
        (c) => c.HowItWorksPageComponent,
      ),
  },
  {
    path: 'search',
    loadComponent: () =>
      import('@app/pages/search-page/search-page.component').then(
        (c) => c.SearchPageComponent,
      ),
  },
  {
    path: 'presse',
    loadComponent: () =>
      import('@app/pages/pressRoom/pressRoom.component').then(
        (c) => c.PressRoomComponent,
      ),
  },
  ...jobRoutes,
  {
    path: ':headCategory',
    children: [...mainCatRoutes, ...seoRoutes],
  },
  {
    path: '**',
    loadComponent: () =>
      import('@components/shared/error/error.component').then(
        (c) => c.ErrorComponent,
      ),
  },
];
