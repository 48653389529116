import { Component, Input, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatMenuModule, MatMenu } from '@angular/material/menu';
import { environment } from '../../../../environments/handyhand/environment.dk';
import { TranslatePipe } from '@app/pipes/translate.pipe';

@Component({
  imports: [MatMenuModule, RouterModule, TranslatePipe],
  selector: 'app-vertical-link-box-component',
  templateUrl: './verticalLinkBox.html',
  styleUrls: ['./verticalLinkBox.scss'],
})
export class VerticalLinkBoxComponent {
  @Input() urlEqualsLp: any;
  @Input() scrollPosition: any;
  @Input() currentTranslationLanguageTrigger: any;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu | undefined;

  verticalsLinks = [
    ['Affaldsfjernelse', '/affald/affaldsfjernelse'],
    ['Afhentning af byggeaffald', '/affald/byggeaffald-service'],
    ['Afhentning af haveaffald', '/affald/haveaffald-service'],
    ['Afhentning af køleskab', '/flytning/afhentning-af-koeleskab'],
    ['Afhentning af møbler', '/flytning/afhentning-af-moebler'],
    ['Afhentning af storskrald', '/affald/afhentningstorskrald'],
    ['Altmuligmand', '/handyman/altmuligmand'],
    ['Anlæg af terrasse', '/toemrer/terasselaegger'],
    ['Anlægsgartner', '/have/anlaeg-af-have'],
    ['Blikkenslager', '/vvs-arbejde/blikkenslager'],
    ['Cykel reparation', '/cykel/cykel-service'],
    ['Elektriker', '/elektriker'],
    ['Fliselægger', '/gulv/fliselaegger'],
    ['Flyttehjælp', '/flytning/flyttemand'],
    ['Fodterapeut', '/sundhed-og-wellness/fodterapeut'],
    ['Græsslåning', '/have/graesplaene-og-graesslaaning'],
    ['Gulvlaminering', '/gulv/gulvlaminering'],
    ['Hækkeklipning', '/have/regler-haekklipning'],
    ['Handyman', '/handyman'],
    ['Hårde hvidevare Montering & Reperation', '/handyman/haardehvidevare'],
    ['Havearbejde', '/have/havearbejde'],
    ['Hjælp til hårde hvidevarer', '/haarde-hvidevarer/hvidevarer-hjaelp'],
    ['Hjælp til mobil, pc, tv', '/it/hjaelp-til-mobil-pc-tv'],
    ['Hundepasning', '/kaeledyr/hund-pasning'],
    ['IKEA møbelsamling', '/moebler/ikea-montering'],
    ['Installation af ildsted', '/handyman/installtion-af-ildsted'],
    [
      'Installation af stikkontakter',
      '/elektriker/installation-af-stik-kontakter',
    ],
    ['Lejligheds rengøring', '/rengoering/lejlighedsrengoering'],
    ['Maler', '/maler'],
    ['Møbelsamling og montering', '/moebler/moebel-montoer'],
    ['Mobil frisør', '/skoenhed/frisoer'],
    ['Montering af køkken', '/toemrer/montering-af-koekken'],
    ['Ophængning af lamper', '/elektriker/lampe-montoer'],
    ['Opsætning af emhætte', '/handyman/emhaette-installatoer'],
    ['Opsætning af hjemme netværk', '/it/hjemme-netvaerk'],
    ['Opsætning af skillevæg', '/toemrer/skillevaeg'],
    ['Rengøring', '/rengoering'],
    ['Reparation af tagrende', '/taglaegning/reparation-af-tagrende'],
    [
      'Servering eller anden hjælp til fest',
      '/events-og-arrangementer/servering-eller-anden-hjaelp-til-fest',
    ],
    ['Tømrer', '/toemrer'],
    ['Træfældning', '/have/traefaeldning'],
    ['Transport af genstande', '/flytning/transport-genstande'],
    ['Tunge løft', '/flytning/tungeloeft'],
    ['TV installation og opsætning', '/montering/tv-installatoer'],
    ['Udflytnings rengøring', '/rengoering/udflytningsrengoering'],
    ['Underholdning', '/underholdning/underholder'],
    ['Vinduespudsning', '/rengoering/vinduespudser'],
    ['VVS', '/vvs-arbejde/vvs'],
  ];

  protected readonly environment = environment;
}
